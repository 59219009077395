/**
 * <PRE>
 * System Name : 신태블릿 영업지원 시스템 (TSS)
 * Business Name : 사고보험금
 * Class Name : TrnsJsonCretUtil.js
 * Description : 전자서명 앱 호출 전 Data Json 구조화
 * </PRE>
 * @version 1.0
 * @author Copyright (C) 2010 by SAMSUNG SDS co.,Ltd. All right reserved.
 */

const TrnsJsonCretUtil = {}

/******************************************************************************
 * Function명 : createJsonData
 * 설명       : VO 안에 있는 데이터를 Json 구조화
******************************************************************************/
TrnsJsonCretUtil.createJsonData = function (fNInsrFnEltrnSignDoctVOArray, busnScCd) {
  let fNInsrFnEltrnSignDoctListVO = {}
  let fNEltrnDocDataFldVO = []
  let fieldNm = ''
  let fieldData = ''
  let fieldDataList = ''

  let FORM_ARY = []
  let DATA_ARY = []
  let META_ARY = []
  let PBL_DFNT = []
  let fromVo = {}
  let tmpVo = {}
  let tmpListVo = {}
  let jsonData = {}
  let busnScCdPath = ''

  console.log('createJsonData busnScCd : ' + busnScCd)
  if (busnScCd === 'FN') {
    busnScCdPath = '/CL/'
  } else if (busnScCd === 'NB') {
    busnScCdPath = '/ADD/'
  } else if (busnScCd === 'BU') {
    busnScCdPath = '/FC/'
  }
  console.log('createJsonData busnScCdPath : ' + busnScCdPath)
  if (fNInsrFnEltrnSignDoctVOArray !== null && typeof fNInsrFnEltrnSignDoctVOArray !== 'undefined') {
    for (let i = 0; i < fNInsrFnEltrnSignDoctVOArray.length; i++) {
      fNInsrFnEltrnSignDoctListVO = fNInsrFnEltrnSignDoctVOArray[i]
      FORM_ARY.push({'formId': busnScCdPath + fNInsrFnEltrnSignDoctListVO.docNm})
      if (fNInsrFnEltrnSignDoctListVO.fNEltrnDocDataFldVO !== null && typeof fNInsrFnEltrnSignDoctListVO.fNEltrnDocDataFldVO !== 'undefined') {
        for (let j = 0; j < fNInsrFnEltrnSignDoctListVO.fNEltrnDocDataFldVO.length; j++) {
          fNEltrnDocDataFldVO = fNInsrFnEltrnSignDoctListVO.fNEltrnDocDataFldVO[j]
          if (fNEltrnDocDataFldVO.fieldData !== null && typeof fNEltrnDocDataFldVO.fieldData !== 'undefined') {
            fieldNm = fNEltrnDocDataFldVO.fieldNm
            fieldData = fNEltrnDocDataFldVO.fieldData
            tmpVo[`${fieldNm}`] = fieldData
          }
        }
        fromVo['SingleValueMap'] = tmpVo

        let colName = []
        let colData1 = []
        let colData2 = []
        let rowData = []
        for (let j = 0; j < fNInsrFnEltrnSignDoctListVO.fNEltrnDocDataFldVO.length; j++) {
          fNEltrnDocDataFldVO = fNInsrFnEltrnSignDoctListVO.fNEltrnDocDataFldVO[j]
          if (fNEltrnDocDataFldVO.fieldDataList !== null && typeof fNEltrnDocDataFldVO.fieldDataList !== 'undefined') {
            colName.push(fNEltrnDocDataFldVO.fieldNm)
            for (let k = 0; k < fNEltrnDocDataFldVO.fieldDataList.length; k++) {
              fieldDataList = fNEltrnDocDataFldVO.fieldDataList[k]
              colData2.push(fieldDataList)
            }
            colData1.push(colData2)
            colData2 = []
          }
        }
        if (colData1[0] !== null && typeof colData1[0] !== 'undefined') {
          for (let j = 0; j < colData1[0].length; j++) { // col 길이만큼
            for (let k = 0; k < colData1.length; k++) { // row 길이만큼
              tmpListVo[`${colName[k]}`] = colData1[k][j]
            }
            rowData.push(tmpListVo)
            fromVo['Array'] = rowData
            tmpListVo = {}
          }
        }
      }
      DATA_ARY.push(fromVo)
      META_ARY.push({'displayname': fNInsrFnEltrnSignDoctListVO.docNm, 'color': '', 'duplex': '', 'WTMK_NO': ''})
      PBL_DFNT.push({'fcname': '', 'fccode': '', 'tbtpblkindnm': '', 'title': '', 'merge': '', 'entplid': '', 'tbtpblno': '', 'channel': '', 'outgoing': ''})
      tmpVo = {}
      fromVo = {}
    }
  }
  let servlet_addr = window.vue.config.http.baseURI + '/OZRepeaterServlet'
  console.log('servlet_addr : ' + servlet_addr)
  jsonData = {'servlet_addr': servlet_addr} // OZ 서블릿 경로
  jsonData.FORM_ARY = FORM_ARY
  jsonData.DATA_ARY = DATA_ARY
  jsonData.META_ARY = META_ARY
  // JSON.stringify : Json -> JsonString
  // JSON.parse : JsonString -> Json
  // JSON.stringify(jsonData)
  return jsonData
}

export default TrnsJsonCretUtil
